import React from 'react';
import './good-beef-shield.scss';
import PropTypes from 'prop-types';

/* eslint-disable max-len */
const GoodBeefSheild = ({ className, score, id }) => (
  <svg className={ className ? className + ' ' + 'bb-good-beef-shield' : 'bb-good-beef-shield' } viewBox="0 0 98.743 87.903">
    <path d="M99.372,14.17a96.29,96.29,0,0,0-98.744,0A96.3,96.3,0,0,0,50,88.451,96.3,96.3,0,0,0,99.372,14.17Z" transform="translate(-0.628 -0.549)"/>
    <g className="shield-text-group">
      <text textAnchor="middle" className="shield-text-group">
        <tspan x="50%" y="50%" dy="-0.2em" className="shield-score" id={ 'score_' + id}>
          { score || 0 }
        </tspan>
        <tspan x="50%" y="50%" dy="0.75em" className="shield-out-of">
          /120
        </tspan>
      </text>
    </g>
  </svg>
);

GoodBeefSheild.propTypes = {
  className: PropTypes.string,
  score: PropTypes.number,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

export default GoodBeefSheild;
