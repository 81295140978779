import React from 'react';
import PropTypes from 'prop-types';
import './gbi-grade-shield.scss';

/* eslint-disable max-len */
const GBIGradeShield = ({ grade }) => {
  let gradeText = (
    <g className="standard grade-text" data-cy="grade-text-standard">
      <path d="M216.13,266a47.74,47.74,0,0,1,18.08,15.05l-14.81,14.5A28.92,28.92,0,0,0,208.63,283c-4.48-2.76-10.12-4.14-17-4.14-6.24,0-11,1.18-14.43,3.51a10.83,10.83,0,0,0-5,9.29q0,5.29,5.14,8.42t18.55,5.46q14,2.64,22.32,6.55t12.07,9.74c2.55,3.91,3.84,8.76,3.84,14.59a26,26,0,0,1-5.39,16.45,34,34,0,0,1-15,10.7,60,60,0,0,1-21.91,3.74q-17,0-27.84-4.77a40.93,40.93,0,0,1-17.55-14.42l12.79-15.29Q165,342.36,172.46,346c5,2.44,11.17,3.66,18.55,3.66,6.67,0,11.71-1,15.22-3.12a9.84,9.84,0,0,0,5.21-8.89c0-2.81-1.64-5.15-4.9-7S197.41,327,189,325.34q-14.5-3-23.17-7t-12.55-10c-2.59-3.94-3.9-8.84-3.9-14.66a29,29,0,0,1,5-16.61q5-7.41,14.57-11.62c6.41-2.81,13.91-4.22,22.55-4.22Q205.81,261.23,216.13,266Z" transform="translate(-3.54 -4)"/>
      <path d="M308.39,360.91a28,28,0,0,1-9.43,4.68A41.42,41.42,0,0,1,287,367.31q-26,0-26-23.56V303.66h-14.2V287.75H261V269.82l21.36-5.93v23.86h25.27v15.91H282.34v36.66c0,7.17,3.6,10.77,10.76,10.77a18.1,18.1,0,0,0,12-4.37Z" transform="translate(-3.54 -4)"/>
      <path d="M402,352.17l-1.57,13a22.43,22.43,0,0,1-10.14,2.18q-15,0-17.94-11.7a21.31,21.31,0,0,1-10.6,8.73,44.28,44.28,0,0,1-17,3q-11.4,0-17.79-5.15t-6.38-14.82q0-19,30.41-24.65l18.72-3.59v-3.73q0-6.24-3.53-9.91t-9.74-3.67c-4.78,0-8.69,1-11.69,3.12s-5.1,5.47-6.24,10.15l-17-7.18A25.81,25.81,0,0,1,332.68,292q8.76-5.78,22.8-5.78,16.68,0,26,7.25t9.36,21.46v32c0,2.09.45,3.59,1.38,4.53a6,6,0,0,0,4.38,1.4A18.34,18.34,0,0,0,402,352.17Zm-38-2.73c3.78-2.23,5.69-5.27,5.69-9.12v-8.11L353,336a19.82,19.82,0,0,0-7.88,3.36,7.16,7.16,0,0,0-2.57,5.85,7,7,0,0,0,2.33,5.69,10,10,0,0,0,6.55,1.95A24.31,24.31,0,0,0,364,349.44Z" transform="translate(-3.54 -4)"/>
      <path d="M484,293.53q7.71,7.32,7.72,21.05v51.17H470.38V320.82q0-17.77-13.88-17.78a17.25,17.25,0,0,0-12.72,5.3q-5.22,5.31-5.21,16.23v41.18H417.2v-78h19.18l.61,13.89a27,27,0,0,1,10.77-11.54,31.54,31.54,0,0,1,15.91-3.91Q476.32,286.19,484,293.53Z" transform="translate(-3.54 -4)"/>
      <path d="M590.89,253.59V365.75h-19l-1-14.51a27.53,27.53,0,0,1-10.22,11.7,28,28,0,0,1-15.5,4.37q-15.62,0-24.51-10.69T511.8,326.9q0-19.18,8.88-29.94t24.51-10.77A27.52,27.52,0,0,1,559.83,290a25.82,25.82,0,0,1,9.67,10.53v-47Zm-26.22,91.26c3.24-4.06,4.83-9.84,4.83-17.32V326c0-7.38-1.59-13.13-4.83-17.24s-7.65-6.15-13.24-6.15-10.2,2.07-13.44,6.24-4.83,10.13-4.83,17.93,1.62,13.78,4.83,17.94,7.7,6.24,13.44,6.24S561.45,348.9,564.67,344.85Z" transform="translate(-3.54 -4)"/>
      <path d="M692.08,352.17l-1.57,13a22.47,22.47,0,0,1-10.15,2.18q-15,0-17.93-11.7a21.39,21.39,0,0,1-10.6,8.73,44.32,44.32,0,0,1-17,3q-11.4,0-17.79-5.15t-6.39-14.82q0-19,30.42-24.65l18.72-3.59v-3.73q0-6.24-3.53-9.91t-9.74-3.67c-4.79,0-8.69,1-11.69,3.12s-5.1,5.47-6.24,10.15l-17-7.18A25.86,25.86,0,0,1,622.8,292q8.76-5.78,22.79-5.78,16.68,0,26.06,7.25T681,314.9v32c0,2.09.46,3.59,1.39,4.53a6,6,0,0,0,4.38,1.4A18.39,18.39,0,0,0,692.08,352.17Zm-38-2.73c3.79-2.23,5.7-5.27,5.7-9.12v-8.11L643.09,336a19.69,19.69,0,0,0-7.88,3.36,7.13,7.13,0,0,0-2.57,5.85,7,7,0,0,0,2.33,5.69,10,10,0,0,0,6.55,1.95A24.27,24.27,0,0,0,654.09,349.44Z" transform="translate(-3.54 -4)"/>
      <path d="M759.88,287.91l-2.64,18.71q-3.62-2.33-9.84-2.33a18,18,0,0,0-13.19,5.54q-5.54,5.54-5.53,15.51v40.41H707.32v-78h18.55L727.28,303q6.39-16.85,24.17-16.85Q756.91,286.19,759.88,287.91Z" transform="translate(-3.54 -4)"/>
      <path d="M850.31,253.59V365.75h-19l-.95-14.51a27.59,27.59,0,0,1-10.22,11.7,28,28,0,0,1-15.5,4.37q-15.61,0-24.51-10.69t-8.88-29.72q0-19.18,8.88-29.94t24.51-10.77A27.52,27.52,0,0,1,819.25,290a25.89,25.89,0,0,1,9.67,10.53v-47Zm-26.22,91.26c3.24-4.06,4.83-9.84,4.83-17.32V326c0-7.38-1.59-13.13-4.83-17.24s-7.65-6.15-13.24-6.15-10.2,2.07-13.44,6.24-4.83,10.13-4.83,17.93,1.62,13.78,4.83,17.94,7.7,6.24,13.44,6.24S820.87,348.9,824.09,344.85Z" transform="translate(-3.54 -4)"/>
    </g>
  );
  switch (grade.toLowerCase()) {
    case 'superior':
      gradeText = (
        <g className="superior grade-text" data-cy="grade-text-superior">
          <path d="M242.87,266A47.81,47.81,0,0,1,261,281.05l-14.82,14.5A28.83,28.83,0,0,0,235.37,283c-4.48-2.76-10.12-4.14-17-4.14-6.24,0-11.05,1.18-14.43,3.51a10.83,10.83,0,0,0-5.05,9.29q0,5.29,5.15,8.42t18.55,5.46q14,2.64,22.31,6.55T257,321.83c2.55,3.91,3.83,8.76,3.83,14.59a26,26,0,0,1-5.38,16.45,34,34,0,0,1-15,10.7,60,60,0,0,1-21.91,3.74q-17,0-27.84-4.77a40.93,40.93,0,0,1-17.55-14.42l12.79-15.29q5.74,9.53,13.26,13.19t18.55,3.66c6.67,0,11.72-1,15.22-3.12a9.83,9.83,0,0,0,5.22-8.89q0-4.21-4.91-7t-17.55-5.31q-14.5-3-23.17-7c-5.76-2.7-10-6-12.55-10s-3.91-8.84-3.91-14.66a29.06,29.06,0,0,1,5-16.61,33.16,33.16,0,0,1,14.58-11.62q9.6-4.21,22.55-4.22Q232.55,261.23,242.87,266Z" transform="translate(-3.54 -4)"/>
          <path d="M354.82,287.75v78h-19L335,352.33q-7.5,15-27.29,15-12.64,0-20.05-6.79c-4.93-4.53-7.41-11.56-7.41-21.14V287.75h21.36v46.18q0,9,3.43,12.79t10.15,3.74c5.4,0,9.81-1.74,13.19-5.23s5.05-8.71,5.05-15.67V287.75Z" transform="translate(-3.54 -4)"/>
          <path d="M449.11,296.87Q458,307.58,458,326.59q0,19.19-8.88,30T424.6,367.31A27.36,27.36,0,0,1,410,363.48,26.9,26.9,0,0,1,400.12,353V398.5H378.76V287.75h18.86l.79,15.45a28.36,28.36,0,0,1,10.21-12.4,27.81,27.81,0,0,1,16-4.61Q440.22,286.19,449.11,296.87ZM431.8,344.69q4.81-6.24,4.83-17.94t-4.83-17.93q-4.86-6.26-13.58-6.24A16.12,16.12,0,0,0,405.58,308q-4.83,5.37-5.46,15.36v6.86q.61,9.82,5.46,15.29a16.05,16.05,0,0,0,12.64,5.46Q426.93,350.93,431.8,344.69Z" transform="translate(-3.54 -4)"/>
          <path d="M550.9,334.86H495.69q3.26,16.53,19.48,16.54a22.13,22.13,0,0,0,11.08-2.5,13.33,13.33,0,0,0,6.09-7.17l17.46,6.39a27.55,27.55,0,0,1-12.55,14.27q-8.82,4.91-22.08,4.92-19.32,0-29.93-10.69t-10.62-29.87q0-19.19,10.31-29.88t29-10.68q18.08,0,27.84,10.46t9.74,29.79A55,55,0,0,1,550.9,334.86Zm-49.52-28.39q-4.61,4.53-5.86,13.42h36.35c-.84-5.82-2.81-10.28-5.93-13.34s-7.17-4.6-12.17-4.6C508.58,302,504.43,303.46,501.38,306.47Z" transform="translate(-3.54 -4)"/>
          <path d="M623.84,287.91l-2.64,18.71q-3.62-2.33-9.84-2.33a18,18,0,0,0-13.19,5.54q-5.53,5.54-5.53,15.51v40.41H571.28v-78h18.55L591.24,303q6.39-16.85,24.17-16.85Q620.87,286.19,623.84,287.91Z" transform="translate(-3.54 -4)"/>
          <path d="M636.75,265.14c0-7.69,4.4-11.55,13.26-11.55s13.25,3.86,13.25,11.55-4.41,11.38-13.25,11.38S636.75,272.72,636.75,265.14Zm23.86,100.61H639.25v-78h21.36Z" transform="translate(-3.54 -4)"/>
          <path d="M749.82,297q10.5,10.77,10.53,29.79t-10.53,29.79q-10.53,10.77-29.41,10.77T691,356.54q-10.5-10.76-10.53-29.79T691,297q10.54-10.77,29.41-10.77T749.82,297ZM706.6,308.27q-4.76,6.17-4.74,18.48t4.74,18.48q4.78,6.16,13.81,6.17c6,0,10.62-2.06,13.81-6.17S739,335,739,326.75s-1.57-14.37-4.74-18.48-7.78-6.17-13.81-6.17S709.79,304.16,706.6,308.27Z" transform="translate(-3.54 -4)"/>
          <path d="M832.94,287.91l-2.65,18.71c-2.4-1.55-5.69-2.33-9.83-2.33a18,18,0,0,0-13.19,5.54c-3.7,3.69-5.53,8.86-5.53,15.51v40.41H780.38v-78h18.55L800.34,303q6.39-16.85,24.17-16.85Q830,286.19,832.94,287.91Z" transform="translate(-3.54 -4)"/>
        </g>
      );
      break;
    case 'classic':
      gradeText = (
        <g className="classic grade-text" data-cy="grade-text-classic">
          <path d="M305.61,359.74q-11.53,7.56-27.6,7.57-15.15,0-26.37-6.4a42.67,42.67,0,0,1-17.24-18.32q-6-11.94-6-28.32t6-28.3a42.69,42.69,0,0,1,17.17-18.34q11.15-6.4,26.13-6.4,16.07,0,27.22,6.71t15.36,20l-19.51,8.27Q298.28,287,293,282.92t-14.36-4.06q-13.11,0-20.51,9.2t-7.41,26.21q0,17,7.17,26.21t20.29,9.2q18.87,0,23.7-18.25l19.81,5.93Q317.15,352.18,305.61,359.74Z" transform="translate(-3.54 -4)"/>
          <path d="M360.5,340.94q0,5.63,1.65,7.8c1.07,1.47,3.07,2.19,6,2.19a23.74,23.74,0,0,0,4.59-.39,33,33,0,0,0,4.6-1.33L375.48,364a20.37,20.37,0,0,1-6.55,2.42,38.62,38.62,0,0,1-8,.86c-7.58,0-13.13-1.85-16.6-5.54s-5.24-9.6-5.24-17.71V253.59H360.5Z" transform="translate(-3.54 -4)"/>
          <path d="M470.86,352.17l-1.57,13a22.46,22.46,0,0,1-10.14,2.18q-15,0-17.94-11.7a21.31,21.31,0,0,1-10.6,8.73,44.28,44.28,0,0,1-17,3q-11.4,0-17.79-5.15t-6.38-14.82q0-19,30.41-24.65l18.72-3.59v-3.73q0-6.24-3.53-9.91t-9.74-3.67c-4.78,0-8.69,1-11.69,3.12s-5.1,5.47-6.24,10.15l-17-7.18A25.81,25.81,0,0,1,401.58,292q8.76-5.78,22.8-5.78,16.68,0,26.05,7.25t9.36,21.46v32c0,2.09.45,3.59,1.38,4.53a6,6,0,0,0,4.38,1.4A18.39,18.39,0,0,0,470.86,352.17Zm-38-2.73c3.78-2.23,5.69-5.27,5.69-9.12v-8.11L421.87,336a19.82,19.82,0,0,0-7.88,3.36,7.16,7.16,0,0,0-2.57,5.85,7,7,0,0,0,2.33,5.69,10,10,0,0,0,6.55,1.95A24.31,24.31,0,0,0,432.88,349.44Z" transform="translate(-3.54 -4)"/>
          <path d="M482.94,352.33l12.48-12.79a18.54,18.54,0,0,0,8.95,9.44,34.09,34.09,0,0,0,14.43,2.73c4.57,0,8.12-.62,10.62-1.87s3.74-3,3.74-5.15c0-1.77-1.16-3.28-3.52-4.52s-6.36-2.44-12.08-3.6q-17.92-3.57-25.19-9.43T485.1,311.3A21,21,0,0,1,489.18,299q4-5.78,12-9.28c5.31-2.34,11.64-3.51,19-3.51q13.11,0,21.22,4A33.73,33.73,0,0,1,555,302.73L541.74,314a23.19,23.19,0,0,0-8.51-9.2q-5.07-3-12.71-3-6.4,0-10.22,2.19c-2.55,1.45-3.81,3.22-3.81,5.3s1.24,4,3.74,5.45,6.91,2.81,13.26,4.06q17,3.43,24.1,9c4.72,3.69,7.1,8.81,7.1,15.37q0,10.92-9.29,17.54t-26.29,6.64Q493.07,367.31,482.94,352.33Z" transform="translate(-3.54 -4)"/>
          <path d="M568.41,352.33l12.48-12.79a18.54,18.54,0,0,0,9,9.44,34.09,34.09,0,0,0,14.43,2.73q6.87,0,10.63-1.87c2.47-1.25,3.74-3,3.74-5.15,0-1.77-1.17-3.28-3.53-4.52s-6.36-2.44-12.07-3.6q-17.94-3.57-25.2-9.43t-7.26-15.84A21,21,0,0,1,574.65,299q4-5.78,12-9.28t19-3.51q13.11,0,21.22,4a33.67,33.67,0,0,1,13.57,12.56L627.21,314a23.24,23.24,0,0,0-8.5-9.2c-3.39-2-7.62-3-12.72-3q-6.39,0-10.22,2.19c-2.55,1.45-3.81,3.22-3.81,5.3s1.24,4,3.74,5.45,6.91,2.81,13.27,4.06q17,3.43,24.1,9c4.71,3.69,7.09,8.81,7.09,15.37q0,10.92-9.28,17.54t-26.3,6.64Q578.54,367.31,568.41,352.33Z" transform="translate(-3.54 -4)"/>
          <path d="M656.76,265.14q0-11.53,13.27-11.55t13.24,11.55q0,11.37-13.24,11.38T656.76,265.14Zm23.87,100.61H659.26v-78h21.37Z" transform="translate(-3.54 -4)"/>
          <path d="M764.29,292.43A31.05,31.05,0,0,1,776,309.13l-20,7.16c-1.88-9.45-6.9-14.19-15.12-14.19-6.14,0-10.86,2.11-14.12,6.32s-4.91,10.37-4.91,18.48,1.65,14.23,5,18.33,8,6.17,13.89,6.17q13.71,0,16.21-13.89l19.37,6.24a28.08,28.08,0,0,1-11.63,17.16q-9.13,6.39-23.64,6.4-19.34,0-29.94-10.69t-10.62-29.87q0-19.19,10.52-29.88t29.27-10.68Q755.72,286.19,764.29,292.43Z" transform="translate(-3.54 -4)"/>
        </g>
      );
      break;
    case 'prime':
      gradeText = (
        <g className="prime grade-text" data-cy="grade-text-prime">
          <path d="M358.22,271.45q10,8.67,10,24.41,0,15.28-10,23.88t-27.91,8.57h-21.7v37.44H287.09v-103h43.22Q348.25,262.79,358.22,271.45Zm-16.53,36.19q4.68-4,4.67-11.93c0-5.3-1.55-9.26-4.67-11.85S333.67,280,327,280H308.61v31.66H327Q337,311.61,341.69,307.64Z" transform="translate(-3.54 -4)"/>
          <path d="M439.07,287.91l-2.64,18.71c-2.4-1.55-5.69-2.33-9.83-2.33a18,18,0,0,0-13.2,5.54q-5.53,5.54-5.52,15.51v40.41H386.51v-78h18.56l1.4,15.29q6.39-16.85,24.17-16.85Q436.12,286.19,439.07,287.91Z" transform="translate(-3.54 -4)"/>
          <path d="M452,265.14q0-11.53,13.27-11.55t13.24,11.55q0,11.37-13.24,11.38T452,265.14Zm23.86,100.61H454.48v-78h21.36Z" transform="translate(-3.54 -4)"/>
          <path d="M617.4,293.53c5.24,4.88,7.89,11.9,7.89,21.05v51.17H603.9v-47q0-8.1-3.57-11.93c-2.41-2.55-5.67-3.82-9.84-3.82a15.7,15.7,0,0,0-12.31,5.45c-3.24,3.65-4.84,8.74-4.84,15.29v42H552v-47q0-8.1-3.57-11.93t-10-3.82a16.06,16.06,0,0,0-12.07,5.38q-5.07,5.38-5.07,16v41.34H499.87v-78h19.18l.61,13.89a27.13,27.13,0,0,1,10.7-11.54,31.6,31.6,0,0,1,30.72.15A23.64,23.64,0,0,1,571,302a26.47,26.47,0,0,1,10.93-11.7,31.19,31.19,0,0,1,15.6-4.06Q609.52,286.19,617.4,293.53Z" transform="translate(-3.54 -4)"/>
          <path d="M721.19,334.86H666q3.24,16.53,19.48,16.54a22.12,22.12,0,0,0,11.07-2.5,13.35,13.35,0,0,0,6.1-7.17l17.45,6.39a27.51,27.51,0,0,1-12.55,14.27q-8.82,4.91-22.07,4.92-19.34,0-29.94-10.69t-10.62-29.87q0-19.19,10.31-29.88t29-10.68q18.07,0,27.84,10.46t9.74,29.79A53.73,53.73,0,0,1,721.19,334.86Zm-49.51-28.39q-4.62,4.53-5.86,13.42h36.34c-.83-5.82-2.81-10.28-5.93-13.34s-7.17-4.6-12.17-4.6C678.87,302,674.73,303.46,671.68,306.47Z" transform="translate(-3.54 -4)"/>
        </g>
      );
      break;
    case 'supreme':
      gradeText = (
        <g className="supreme grade-text" data-cy="grade-text-supreme">
          <path d="M226.75,266a47.74,47.74,0,0,1,18.08,15.05L230,295.55A28.85,28.85,0,0,0,219.25,283c-4.47-2.76-10.12-4.14-17-4.14-6.24,0-11.05,1.18-14.43,3.51a10.83,10.83,0,0,0-5,9.29q0,5.29,5.14,8.42t18.55,5.46q14,2.64,22.32,6.55t12.07,9.74c2.55,3.91,3.84,8.76,3.84,14.59a26,26,0,0,1-5.38,16.45,34.11,34.11,0,0,1-15,10.7,60,60,0,0,1-21.91,3.74q-17,0-27.84-4.77A41,41,0,0,1,157,348.12l12.79-15.29q5.76,9.53,13.27,13.19t18.55,3.66c6.67,0,11.72-1,15.22-3.12a9.84,9.84,0,0,0,5.21-8.89c0-2.81-1.64-5.15-4.9-7S208,327,199.61,325.34q-14.51-3-23.18-7t-12.55-10c-2.59-3.94-3.9-8.84-3.9-14.66a29,29,0,0,1,5-16.61q5-7.41,14.57-11.62c6.41-2.81,13.91-4.22,22.56-4.22Q216.44,261.23,226.75,266Z" transform="translate(-3.54 -4)"/>
          <path d="M338.71,287.75v78h-19l-.78-13.42q-7.51,15-27.3,15-12.64,0-20.05-6.79c-4.93-4.53-7.41-11.56-7.41-21.14V287.75h21.37v46.18q0,9,3.43,12.79t10.14,3.74a17.58,17.58,0,0,0,13.2-5.23q5-5.22,5-15.67V287.75Z" transform="translate(-3.54 -4)"/>
          <path d="M433,296.87q8.86,10.71,8.89,29.72,0,19.19-8.89,30t-24.5,10.77a27.42,27.42,0,0,1-14.65-3.83A26.88,26.88,0,0,1,384,353V398.5H362.64V287.75h18.87l.78,15.45a28.38,28.38,0,0,1,10.22-12.4,27.78,27.78,0,0,1,16-4.61Q424.1,286.19,433,296.87Zm-17.31,47.82q4.83-6.24,4.83-17.94t-4.83-17.93q-4.86-6.26-13.57-6.24A16.13,16.13,0,0,0,389.46,308c-3.22,3.58-5,8.7-5.45,15.36v6.86c.4,6.55,2.23,11.64,5.45,15.29a16.06,16.06,0,0,0,12.65,5.46Q410.83,350.93,415.68,344.69Z" transform="translate(-3.54 -4)"/>
          <path d="M514.8,287.91l-2.64,18.71c-2.41-1.55-5.7-2.33-9.84-2.33a18,18,0,0,0-13.19,5.54q-5.53,5.54-5.53,15.51v40.41H462.24v-78h18.55L482.2,303q6.39-16.85,24.17-16.85Q511.83,286.19,514.8,287.91Z" transform="translate(-3.54 -4)"/>
          <path d="M602,334.86H546.78q3.26,16.53,19.48,16.54a22.13,22.13,0,0,0,11.08-2.5,13.33,13.33,0,0,0,6.09-7.17l17.46,6.39a27.55,27.55,0,0,1-12.55,14.27q-8.82,4.91-22.08,4.92-19.32,0-29.93-10.69t-10.62-29.87q0-19.19,10.31-29.88t29-10.68q18.08,0,27.84,10.46t9.74,29.79A53.73,53.73,0,0,1,602,334.86Zm-49.52-28.39q-4.61,4.53-5.85,13.42H583c-.84-5.82-2.81-10.28-5.93-13.34s-7.17-4.6-12.17-4.6C559.67,302,555.52,303.46,552.47,306.47Z" transform="translate(-3.54 -4)"/>
          <path d="M740,293.53q7.86,7.32,7.88,21.05v51.17H726.52v-47q0-8.1-3.58-11.93c-2.4-2.55-5.66-3.82-9.83-3.82a15.7,15.7,0,0,0-12.31,5.45c-3.24,3.65-4.84,8.74-4.84,15.29v42H674.58v-47q0-8.1-3.58-11.93T661,303a16,16,0,0,0-12.07,5.38q-5.08,5.38-5.08,16v41.34H622.49v-78h19.17l.62,13.89A27.17,27.17,0,0,1,653,290.1a31.62,31.62,0,0,1,30.73.15,23.57,23.57,0,0,1,9.9,11.7,26.53,26.53,0,0,1,10.93-11.7,31.22,31.22,0,0,1,15.6-4.06Q732.15,286.19,740,293.53Z" transform="translate(-3.54 -4)"/>
          <path d="M843.81,334.86H788.6q3.25,16.53,19.48,16.54a22.13,22.13,0,0,0,11.08-2.5,13.33,13.33,0,0,0,6.09-7.17l17.46,6.39a27.55,27.55,0,0,1-12.55,14.27q-8.82,4.91-22.08,4.92-19.32,0-29.93-10.69t-10.62-29.87q0-19.19,10.31-29.88t29-10.68q18.07,0,27.84,10.46t9.74,29.79A55,55,0,0,1,843.81,334.86Zm-49.52-28.39q-4.6,4.53-5.85,13.42h36.34c-.84-5.82-2.81-10.28-5.93-13.34s-7.17-4.6-12.17-4.6C801.49,302,797.34,303.46,794.29,306.47Z" transform="translate(-3.54 -4)"/>
        </g>
      );
      break;
  }

  return (
    <svg className={ `gbi-grade-shield ${grade.toLowerCase()}`} viewBox="0 0 993.09 884.06">
      <path className="top" d="M996.63,141A968.4,968.4,0,0,0,3.54,141C37.15,463.44,229,738.76,500.08,888.06,771.15,738.76,963,463.44,996.63,141Z" transform="translate(-3.54 -4)"/>
      <path className="bottom" d="M500.08,453.54A643.71,643.71,0,0,0,148.8,557.33c87.34,137.58,208.08,251.86,351.28,330.73C643.28,809.19,764,694.91,851.36,557.33A643.71,643.71,0,0,0,500.08,453.54Z" transform="translate(-3.54 -4)"/>
      <g className="brand-name">
        <path d="M243.33,180.32v27.22h-7.88l-.24-6.15q-4.68,6.93-15.2,6.93a24.45,24.45,0,0,1-12.47-3.11,21.23,21.23,0,0,1-8.39-9.1,31.35,31.35,0,0,1-3-14.23,30.9,30.9,0,0,1,3.07-14.19,21.61,21.61,0,0,1,8.74-9.21,26.41,26.41,0,0,1,13.22-3.19c5.55,0,10.1,1,13.6,3a20.57,20.57,0,0,1,8.31,9.6l-9.91,4.28a10.64,10.64,0,0,0-4.23-6.05,13.22,13.22,0,0,0-7.46-2.06c-4.48,0-8,1.54-10.45,4.6s-3.74,7.44-3.74,13.11c0,5.87,1.21,10.31,3.67,13.34s6,4.51,10.76,4.51a14.24,14.24,0,0,0,8.88-2.61,9,9,0,0,0,3.43-7.61v-1.16H219v-8Z" transform="translate(-3.54 -4)"/>
        <path d="M288.22,173.15c3.53,3.59,5.26,8.55,5.26,14.9s-1.73,11.3-5.26,14.9-8.41,5.37-14.69,5.37-11.2-1.79-14.7-5.37-5.26-8.57-5.26-14.9,1.74-11.31,5.26-14.9,8.41-5.38,14.7-5.38S284.72,169.56,288.22,173.15Zm-21.6,5.65c-1.57,2.06-2.38,5.14-2.38,9.25s.81,7.18,2.38,9.24a9.3,9.3,0,0,0,13.81,0c1.58-2.06,2.39-5.13,2.39-9.24s-.81-7.19-2.39-9.25a9.28,9.28,0,0,0-13.81,0Z" transform="translate(-3.54 -4)"/>
        <path d="M336.3,173.15c3.53,3.59,5.27,8.55,5.27,14.9s-1.74,11.3-5.27,14.9-8.4,5.37-14.69,5.37-11.19-1.79-14.69-5.37-5.27-8.57-5.27-14.9,1.74-11.31,5.27-14.9,8.4-5.38,14.69-5.38S332.8,169.56,336.3,173.15Zm-21.6,5.65c-1.57,2.06-2.38,5.14-2.38,9.25s.81,7.18,2.38,9.24a9.31,9.31,0,0,0,13.82,0c1.57-2.06,2.38-5.13,2.38-9.24s-.81-7.19-2.38-9.25a9.29,9.29,0,0,0-13.82,0Z" transform="translate(-3.54 -4)"/>
        <path d="M389.48,151.47v56.07H380l-.46-7.25a13.75,13.75,0,0,1-5.12,5.85,14,14,0,0,1-7.76,2.18c-5.19,0-9.26-1.78-12.24-5.34s-4.45-8.51-4.45-14.86,1.5-11.38,4.45-15,7.05-5.38,12.24-5.38a13.73,13.73,0,0,1,7.34,1.91,12.93,12.93,0,0,1,4.83,5.26V151.47Zm-13.09,45.62c1.59-2,2.4-4.91,2.4-8.66v-.77c0-3.7-.81-6.56-2.4-8.62a8.72,8.72,0,0,0-13.34,0q-2.43,3.12-2.43,9t2.43,9a8,8,0,0,0,6.69,3.12A8.06,8.06,0,0,0,376.39,197.09Z" transform="translate(-3.54 -4)"/>
        <path d="M402.87,156.07H425.4q9.21,0,13.71,3.63c3,2.41,4.53,5.88,4.53,10.4a11.5,11.5,0,0,1-2.57,7.46,11.37,11.37,0,0,1-7.24,3.93v.08a13.34,13.34,0,0,1,8.21,4,11.26,11.26,0,0,1,2.93,7.84,12.4,12.4,0,0,1-4.83,10.42q-4.83,3.69-14,3.7H402.87Zm21.05,21.6q8.58,0,8.57-6.62t-8.57-6.64H413.3v13.26Zm1.33,21.76c2.86,0,5-.58,6.43-1.76a6.13,6.13,0,0,0,2.15-5,6,6,0,0,0-2.15-5q-2.15-1.72-6.43-1.72H413.3v13.49Z" transform="translate(-3.54 -4)"/>
        <path d="M491.67,192.1h-27.6q1.65,8.27,9.74,8.26a11,11,0,0,0,5.55-1.24,6.69,6.69,0,0,0,3-3.59l8.74,3.2a13.76,13.76,0,0,1-6.27,7.13,22.36,22.36,0,0,1-11.05,2.46c-6.45,0-11.43-1.78-15-5.34s-5.28-8.54-5.28-14.93,1.71-11.38,5.14-14.94,8.26-5.34,14.5-5.34q9,0,13.94,5.22T492,187.88A26.19,26.19,0,0,1,491.67,192.1Zm-24.76-14.2a11.46,11.46,0,0,0-2.93,6.71h18.19a11.35,11.35,0,0,0-3-6.67,8.36,8.36,0,0,0-6.08-2.3A8.45,8.45,0,0,0,466.91,177.9Z" transform="translate(-3.54 -4)"/>
        <path d="M538.19,192.1H510.58q1.65,8.27,9.74,8.26a11,11,0,0,0,5.55-1.24,6.61,6.61,0,0,0,3-3.59l8.74,3.2a13.76,13.76,0,0,1-6.27,7.13,22.36,22.36,0,0,1-11,2.46c-6.45,0-11.43-1.78-15-5.34s-5.28-8.54-5.28-14.93,1.71-11.38,5.14-14.94,8.27-5.34,14.5-5.34q9,0,13.94,5.22t4.85,14.89A27.4,27.4,0,0,1,538.19,192.1Zm-24.77-14.2a11.46,11.46,0,0,0-2.93,6.71h18.19a11.35,11.35,0,0,0-3-6.67,8.36,8.36,0,0,0-6.08-2.3A8.47,8.47,0,0,0,513.42,177.9Z" transform="translate(-3.54 -4)"/>
        <path d="M561.62,168.54h12.31v8H562.07v31H551.4v-31h-6.78v-8h6.78v-4.68c0-4.26,1.31-7.52,4-9.78s6.24-3.4,10.76-3.4a19.29,19.29,0,0,1,4.72.55,9.67,9.67,0,0,1,3.38,1.48l-1.48,7.09a8,8,0,0,0-4.45-1.16c-4.45,0-6.69,2-6.69,6Z" transform="translate(-3.54 -4)"/>
        <path d="M613.87,207.54H603.11V156.07h10.76Z" transform="translate(-3.54 -4)"/>
        <path d="M660.48,171.43c2.57,2.45,3.85,5.95,3.85,10.53v25.58H653.66V185.08c0-5.93-2.33-8.89-6.95-8.89a8.58,8.58,0,0,0-6.36,2.65c-1.74,1.77-2.62,4.47-2.62,8.11v20.59H627.06v-39h9.6l.31,7a13.53,13.53,0,0,1,5.38-5.78,15.81,15.81,0,0,1,8-1.94A14.18,14.18,0,0,1,660.48,171.43Z" transform="translate(-3.54 -4)"/>
        <path d="M714,151.47v56.07h-9.52l-.45-7.25a13.88,13.88,0,0,1-5.12,5.85,14.08,14.08,0,0,1-7.77,2.18c-5.19,0-9.26-1.78-12.24-5.34s-4.45-8.51-4.45-14.86,1.5-11.38,4.45-15,7-5.38,12.24-5.38a13.7,13.7,0,0,1,7.34,1.91,12.93,12.93,0,0,1,4.83,5.26V151.47Zm-13.09,45.62c1.59-2,2.4-4.91,2.4-8.66v-.77c0-3.7-.81-6.56-2.4-8.62a8.72,8.72,0,0,0-13.34,0q-2.43,3.12-2.43,9t2.43,9a8.75,8.75,0,0,0,13.34.07Z" transform="translate(-3.54 -4)"/>
        <path d="M762.24,192.1h-27.6q1.64,8.27,9.74,8.26a11.07,11.07,0,0,0,5.55-1.24,6.63,6.63,0,0,0,3-3.59l8.74,3.2a13.74,13.74,0,0,1-6.26,7.13,22.39,22.39,0,0,1-11,2.46c-6.46,0-11.43-1.78-15-5.34s-5.29-8.54-5.29-14.93,1.71-11.38,5.14-14.94,8.27-5.34,14.51-5.34q9,0,13.93,5.22t4.86,14.89A27.38,27.38,0,0,1,762.24,192.1Zm-24.77-14.2a11.52,11.52,0,0,0-2.93,6.71h18.2a11.35,11.35,0,0,0-3-6.67,8.36,8.36,0,0,0-6.07-2.3A8.49,8.49,0,0,0,737.47,177.9Z" transform="translate(-3.54 -4)"/>
        <path d="M796.6,207.54l-8.81-14.19-9,14.19H766.88l14.34-19.73-13.65-19.27h12.34l8.33,13.65,8.36-13.65h12l-13.82,19.27,14.05,19.73Z" transform="translate(-3.54 -4)"/>
      </g>
      { gradeText }
      <polygon className="tick" points="477.05 733.07 384.53 646.42 403.16 626.52 473.46 692.38 595.43 529.38 617.25 545.71 477.05 733.07"/>
    </svg>
  );
};

GBIGradeShield.propTypes = {
  grade: PropTypes.string.isRequired
};

export default GBIGradeShield;
