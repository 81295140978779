import React, { Component } from 'react';
import GoodBeefSheild from '../icons/components/GoodBeefShield';
import ArrowIcon from '../icons/components/ArrowIcon';
import PropTypes from 'prop-types';
import './good-beef-scale.scss';
import uuid from 'uuid/v4';
import { getShortGradeDescription } from '../../admin/scoreDefinitions/util';
import GBIGradeShield from '../logos/GBIGradeShield';

class GoodBeefScale extends Component {
  constructor (props) {
    super(props);
    this.state = {
      version: window.innerWidth > 900 ? 'desktop' : 'mobile',
      scaleWidth: window.innerWidth * 0.8,
      visible: false,
      visibleTime: null,
      dragging: false,
      currentAdjustmentX: null,
      maxAdjustment: 400
    };
    this.sequestationId = uuid();
    this.markerRef = React.createRef();
    this.scaleRef = React.createRef();
    this.wedgeRef = React.createRef();
    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
    this.handleDragCancel = this.handleDragCancel.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.update = this.update.bind(this);
    this.handleWindowResize = this.handleWindowResize.bind(this);
  }
  componentDidMount () {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleWindowResize);
    this.scrollTimeout = setTimeout(this.handleScroll, 50);
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleWindowResize);
    if (this.scrollTimeout) clearTimeout(this.scrollTimeout);
  }
  setPosition (rawScore) {
    if (!this.state.visible) return;
    try {
      if (this.state.version === 'desktop') {
        let score = rawScore - 70;
        score = score > 0 ? score : 0;
        const pos = score * this.state.scaleWidth / 50;
        this.markerRef.current.style = `transform: translateX(${pos}px)`;
        this.scaleRef.current.style = `transform: translateX(0)`;
        this.wedgeRef.current.style = `transform: translateX(0)`;
      } else {
        const pos = (rawScore - 95) * this.state.scaleWidth / 50;
        this.scaleRef.current.style = `transform: translateX(${-pos}px)`;
        this.wedgeRef.current.style = `transform: translateX(${-pos}px)`;
        this.markerRef.current.style = `transform: translateX(0)`;
      }
    } catch (error) { /* */ }
  }
  getWidth () {
    let scaleWidth = 0;
    const windowWidth = window.innerWidth;
    if (windowWidth >= 1201) scaleWidth = 1100;
    else if (windowWidth >= 901) scaleWidth = windowWidth * 0.85;
    else if (windowWidth >= 701) scaleWidth = windowWidth * 1.1;
    else if (windowWidth <= 700) scaleWidth = 800;
    return scaleWidth;
  }
  handleWindowResize () {
    let scaleWidth = this.getWidth();
    const maxAdjustment = window.innerWidth / 2;
    const version = window.innerWidth > 900 ? 'desktop' : 'mobile';
    this.setState({ scaleWidth, maxAdjustment, version }, this.update);
  }
  handleScroll () {
    if (this.state.visible) return;
    try {
      var rect = this.markerRef.current.getBoundingClientRect();
      var markerTop = rect.top;
      var markerBottom = rect.bottom + 220;
      if ((markerTop >= 0) && (markerBottom <= window.innerHeight)) {
        this.setState({ visible: true, visibleTime: new Date() });
        window.removeEventListener('scroll', this.handleScroll);
        this.update();
      }
    } catch (error) { /* */ }
    let scaleWidth = this.getWidth();
    this.setState({ scaleWidth });
  }
  update () {
    const runTime = this.state.version === 'desktop' ? 10000 : 8000;
    const sequestionElement =
      document.getElementById(`score_${this.sequestationId}`);
    if (!sequestionElement) {
      return window.requestAnimationFrame(this.update);
    }
    const now = new Date();
    const timePassed = now - this.state.visibleTime;
    let rawScore = Math.pow((timePassed / runTime), 0.4) * this.props.score;
    const currentScore = Math.round(rawScore);
    if (rawScore < this.props.score) {
      sequestionElement.innerHTML = currentScore;
      const posScore = rawScore < 70 ? 70 : rawScore;
      this.setPosition(posScore);
      window.requestAnimationFrame(this.update);
    } else {
      sequestionElement.innerHTML = this.props.score;
      this.setPosition(this.props.score);
    }
  };
  handleTouchStart (event) {
    const dragStartX = event.changedTouches[0].clientX;
    this.setState({ dragStartX });
  }
  handleMouseDown (event) {
    const dragStartX = event.clientX;
    this.setState({ dragStartX, dragging: true });
  }
  handleMove (posX) {
    const dragAdjustmentX = posX - this.state.dragStartX;
    let currentAdjustmentX = dragAdjustmentX + this.state.currentAdjustmentX;
    if (currentAdjustmentX > this.state.margin * 2) {
      currentAdjustmentX = this.state.margin * 2;
    } else if (currentAdjustmentX < (-1 * this.state.maxAdjustment)) {
      currentAdjustmentX = (-1 * this.state.maxAdjustment);
    }
    const el = document.getElementById('gbi-scale-display');
    window.requestAnimationFrame(() => {
      el.style.transform = `translateX(${currentAdjustmentX}px`;
    });
  }
  handleTouchMove (event) {
    const touchPosX = event.changedTouches[0].clientX;
    this.handleMove(touchPosX);
  }
  handleMouseMove (event) {
    if (this.state.dragging) {
      const mousePos = event.clientX;
      this.handleMove(mousePos);
    }
  }
  handleDragEnd (posX) {
    const dragAdjustmentX = posX - this.state.dragStartX;
    let currentAdjustmentX = dragAdjustmentX + this.state.currentAdjustmentX;
    if (currentAdjustmentX > this.state.margin * 2) {
      currentAdjustmentX = this.state.margin * 2;
    } else if (currentAdjustmentX < (-1 * this.state.maxAdjustment)) {
      currentAdjustmentX = (-1 * this.state.maxAdjustment);
    }
    const el = document.getElementById('gbi-scale-display');
    window.requestAnimationFrame(() => {
      el.style.transform = `translateX(${currentAdjustmentX}px`;
      this.setState({ currentAdjustmentX, dragging: false });
    });
  }
  handleTouchEnd (event) {
    const touchPosX = event.changedTouches[0].clientX;
    this.handleDragEnd(touchPosX);
  }
  handleMouseUp (event) {
    if (this.state.dragging) {
      const mousePosX = event.clientX;
      this.handleDragEnd(mousePosX);
    }
  }
  handleDragCancel () {
    const el = document.getElementById('gbi-scale-display');
    const currentAdjustmentX = this.state.currentAdjustmentX;
    window.requestAnimationFrame(() => {
      el.style.transform = `translateX(${currentAdjustmentX}px`;
    });
    this.setState({ dragging: false });
  }
  render () {
    return (
      <div id="gbi-scale-display"
        onTouchStart={ this.handleTouchStart.bind(this) }
        onMouseDown={ this.handleMouseDown.bind(this) }
        onTouchEnd={ this.handleTouchEnd.bind(this) }
        onMouseUp={ this.handleMouseUp.bind(this) }
        onTouchMove={ this.handleTouchMove.bind(this) }
        onMouseMove={ this.handleMouseMove.bind(this) }
        onTouchCancel={ this.handleDragCancel.bind(this) }
        onMouseLeave={ this.handleDragCancel.bind(this) }>
        <div ref={ this.markerRef }>
          <div
            className="gbi-marker" >
            <GoodBeefSheild
              id={ this.sequestationId }
              score={ 0 }/>
            <div className="line"></div>
            <div className="bobble"></div>
          </div>
        </div>
        <ul data-cy="gbi-classes" className="gbi-classes" ref={ this.scaleRef }>
          <li className="grade-class">
            <h6>70+</h6>
            <p className="class-title">Standard</p>
            <p>{ getShortGradeDescription('Standard') }</p>
          </li>
          <li className="grade-class">
            <h6>80+</h6>
            <p className="class-title">Superior</p>
            <p>{ getShortGradeDescription('Superior') }</p>
          </li>
          <li className="grade-class">
            <h6>90+</h6>
            <p className="class-title">Classic</p>
            <p>{ getShortGradeDescription('Classic') }</p>
          </li>
          <li className="grade-class">
            <h6>100+</h6>
            <p className="class-title">Prime</p>
            <p>{ getShortGradeDescription('Prime') }</p>
          </li>
          <li className="grade-class">
            <h6>110+</h6>
            <p className="class-title">Supreme</p>
            <p>{ getShortGradeDescription('Supreme') }</p>
          </li>
        </ul>
        <div className="price-scale" ref={ this.wedgeRef }>
          <h4 className="price-title">
            Distinctive Quality
          </h4>
          <ArrowIcon />
          <svg viewBox='0 0 100 100'
            className="price-figure"
            preserveAspectRatio="none">
            <linearGradient id="grade-premium-gradient">
              <stop offset="5%" stopColor="rgba(255,255,255,0)"/>
              <stop offset="95%" stopColor="rgb(201, 47, 109)"/>
            </linearGradient>
            <polygon
              style={ { fill: 'url(#grade-premium-gradient)' } }
              points="0,50 100,100 100,0"
              className="triangle" />
          </svg>
          <GBIGradeShield grade="standard" />
          <GBIGradeShield grade="superior" />
          <GBIGradeShield grade="classic" />
          <GBIGradeShield grade="prime" />
          <GBIGradeShield grade="supreme" />
        </div>
      </div>
    );
  }
}

GoodBeefScale.propTypes = {
  score: PropTypes.number.isRequired
};

export default GoodBeefScale;
